import React from "react"
import { css } from "@emotion/core"

export default ({ color }) => (
  <div
    css={css`
      background-color: ${color};
      display: block;
      width: 3rem;
      height: 3rem;
    `}
  ></div>
)
