import React from "react"
import { css } from "@emotion/core"

import { accent } from "../../utils/global-styles"

export default ({ children, color }) => (
  <span
    css={css`
      color: ${accent[color].css()};
    `}
  >
    {children}
  </span>
)
