import React from "react"
import { Global, css } from "@emotion/core"

import { rhythm } from "../../utils/typography"
import globalStyles from "../../utils/global-styles"
import SEO from "../seo"
import Footer from "../parts/footer"

const styles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  margin: 0 auto;
  max-width: 40rem;
  main {
    margin-top: 4rem;
    padding: 0 ${rhythm(1)};
    flex: 0 0 auto;
    height: 100%;
  }
`

export default ({ children, title }) => {
  return (
    <div css={styles}>
      <Global styles={globalStyles} />
      <SEO title={title} />
      <main>{children}</main>
      <Footer></Footer>
    </div>
  )
}
