import React from "react"
import { css } from "@emotion/core"

import { rhythm } from "../../utils/typography"

const styles = css`
  display: block;

  :not(:first-child) {
    margin-top: ${rhythm(0.5)};
  }

  .media-left {
    display: table-cell;
    padding-right: 0.5rem;
  }
  .media-body {
    display: table-cell;
    width: 10000px;
  }
  .media-object {
    display: block;
    margin: 0;
    padding: 0;
  }
  .media-heading {
    display: block;
    margin-top: 0;
    margin-top: ${rhythm(0.25)};
  }
`

const Media = ({ css, children }) => <div css={[styles, css]}>{children}</div>

const MediaLeft = (Media.MediaLeft = ({ children }) => (
  <div class="media-left">{children}</div>
))
const MediaBody = (Media.MediaBody = ({ children }) => (
  <div class="media-body">{children}</div>
))
const MediaObject = (Media.MediaObject = ({ children }) => (
  <div class="media-object">{children}</div>
))
const MediaHeading = (Media.MediaHeading = ({ children }) => (
  <div class="media-heading">{children}</div>
))
export default Media
export { MediaLeft, MediaBody, MediaObject, MediaHeading }
