import React from "react"

import { css } from "@emotion/core"
import Layout from "../../components/layout/default"
import Media, { MediaLeft, MediaBody } from "../../components/parts/media"
import Pallete from "../../components/parts/pallete"
import theme, { accent, mono } from "../../themes/light"
import Text from "../../components/parts/text"

export const metadata = {
  title: "Colors",
}

export default () => (
  <Layout title={metadata.title}>
    <h1 children={metadata.title}></h1>

    <p>
      このページは色を調整するページだ。作品のイメージが、たぶん「曇った空」「複雑」「落ち着いている」「(性格的に)暗い」、というものだと思うので、それに合わせた色味になるよう意図している。また、もし音楽や歌詞を味わうなら、それはある程度の滞在時間を意味するはずなので、長時間見ていても疲れない、飽きない色味を目指したい。
    </p>

    <h2>モノトーン</h2>

    <p
      css={css`
        .media-body {
          vertical-align: middle;
        }
      `}
    >
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={mono.base3.css()} />
        </MediaLeft>
        <MediaBody>
          <span class="text-base3">
            base3 {mono.base3.css()} lch(
            {mono.base3
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </span>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={mono.base2.css()} />
        </MediaLeft>
        <MediaBody>
          <span class="text-base2">
            base2 {mono.base2.css()} lch(
            {mono.base2
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </span>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={mono.base1.css()} />
        </MediaLeft>
        <MediaBody>
          <span class="text-base1">
            base1 {mono.base1.css()} lch(
            {mono.base1
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </span>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={mono.base0.css()} />
        </MediaLeft>
        <MediaBody>
          <span class="text-base0">
            base0 {mono.base0.css()} lch(
            {mono.base0
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </span>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={mono.base00.css()} />
        </MediaLeft>
        <MediaBody>
          <span class="text-base00">
            base00 {mono.base00.css()} lch(
            {mono.base00
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </span>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={mono.base01.css()} />
        </MediaLeft>
        <MediaBody>
          <span class="text-base01">
            base01 {mono.base01.css()} lch(
            {mono.base01
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </span>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={mono.base02.css()} />
        </MediaLeft>
        <MediaBody>
          <span class="text-base02">
            base02 {mono.base02.css()} lch(
            {mono.base02
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </span>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={mono.base03.css()} />
        </MediaLeft>
        <MediaBody>
          <span class="text-base03">
            base03 {mono.base03.css()} lch(
            {mono.base03
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </span>
        </MediaBody>
      </Media>
    </p>

    <h2>アクセントカラー Accent Colors</h2>

    <p>　余り使うこともないとは思うが、アクセントとなる色も検討する。</p>

    <p
      css={css`
        .media-body {
          vertical-align: middle;
        }
      `}
    >
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={accent.red.css()} />
        </MediaLeft>
        <MediaBody>
          <span class="text-red">
            赤 red {accent.red.css()} lch(
            {accent.red
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </span>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={accent.orange.css()} />
        </MediaLeft>
        <MediaBody>
          <Text color="orange">
            橙 orange {accent.orange.css()} lch(
            {accent.orange
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </Text>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={accent.yellow.css()} />
        </MediaLeft>
        <MediaBody>
          <Text color="yellow">
            黄色 yellow {accent.yellow.css()} lch(
            {accent.yellow
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </Text>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={accent.green.css()} />
        </MediaLeft>
        <MediaBody>
          <Text color="green">
            緑 green {accent.green.css()} lch(
            {accent.green
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </Text>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={accent.cyan.css()} />
        </MediaLeft>
        <MediaBody>
          <Text color="cyan">
            青緑 cyan {accent.cyan.css()} lch(
            {accent.cyan
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </Text>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={accent.blue.css()} />
        </MediaLeft>
        <MediaBody>
          <Text color="blue">
            青 blue {accent.blue.css()} lch(
            {accent.blue
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </Text>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={accent.violet.css()} />
        </MediaLeft>
        <MediaBody>
          <Text color="violet">
            紫 violet {accent.violet.css()} lch(
            {accent.violet
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </Text>
        </MediaBody>
      </Media>
      <Media>
        <MediaLeft>
          <Pallete className="media-object" color={accent.magenta.css()} />
        </MediaLeft>
        <MediaBody>
          <Text color="magenta">
            赤紫 magenta {accent.magenta.css()} lch(
            {accent.magenta
              .lch()
              .map(v => Math.round(v))
              .join(", ")}
            )
          </Text>
        </MediaBody>
      </Media>
    </p>

    <p>　色を検討するには CIELab カラースペースを使用した。</p>

    <blockquote>
      <h2>デバイス非依存カラースペース</h2>
      <p>
        それぞれのデバイスは、異なるガモットや、各々が作成可能な領域による異なるカラー領域を持っています。これは、RGBやCMYのカラースペースはモニタ間やプリンタ間で様々に変化するという事を意味します。そのため、これらはデバイス依存のカラースペースと呼ばれます。
      </p>
      <p>
        あるデバイス上のRGBから別のデバイス上のCMYKへと変換する場合、特殊な手際が要求されます。ここでデバイス非依存のカラースペースが必要となります。タイトルが示すように、デバイス非依存のカラースペースは特定のデバイスに依存せず、人の眼に映る本当のカラーを意味します。デバイス非依存カラースペースは、あるデバイスのスペースから別のスペースへカラーデータ交換に使用されます。これは、1931年にCommition
        Internationale d'Eclairage
        （CIE）によって行なわれた調査の成果で、そのため、CIEベースのカラースペースとして広く知られています。
      </p>
      <h2>CIEカラースペース</h2>
      <p>
        CIEカラースペースは、カラーマネジメントにおいてデバイス非依存のカラーの基準となるものです。CIEのスペースには２つの種類があります：CIE
        L*a*b*とCIE LChです。
      </p>
      <h2>CIE L*a*b*</h2>
      <p>
        CIE
        L*a*b*これはCIEカラースペースの中でもっとも広く使われているもので、カラーに対する人間の知覚
        -赤･緑･青の３つのカラー受容体-
        に基づいています。その結果、以下の３つの信号のセットを脳に送ります：明/暗、赤/緑、黄/青。あるカラーが持つことができるのは、赤あるいは緑のどちらかの性質だけで両方ではありません。この反対色形式のカラースペースは、CIEの値から数学的に導き出されます。
      </p>
      <p>Lは物体の明度の値で、 0（黒）から100（白）の範囲です。</p>
      <p>aは赤（正の"a"）または緑（負の"a"）の度合いです。</p>
      <p>bは黄（正の"b"）または青（負の"b"）の度合いです。</p>
      <p>
        aとbの座標が0に近づくと中間色（白、グレー及び黒）となります。aとbの値が高くなるほど、その色は鮮やかになります。
      </p>
      <h2>CIE LCh</h2>
      <p>
        このカラースペースはしばしば単純にLChと呼ばれます。
        このシステムは、CIELabのカラースペースとほとんど同じで、違いはカラーのロケーションの表記に、デカルト座標の代わりに極座標を使っているところです。
      </p>
      <p>
        Lは物体の明度の値で、0（黒）から100（白）の範囲です。
        Cは彩度（Chromaあるいはsaturation）で、中心軸からどの位離れているかを示しています。
        hは色相(hue）で0から360の角度範囲で示されます。0から90の範囲は赤・オレンジ・黄、90から180では黄・黄緑・緑、180から270では緑・シアン（青緑）・青、270から360では青・紫・マゼンタを示し、そして赤に戻ります。
      </p>
      --{" "}
      <cite>
        <a href="https://www.xrite.co.jp/colorknowledge/37-color-knowledge-2/35-color-space-2.html">
          Lab色空間、カラースペース - X Rite 日本公式サイト
        </a>
      </cite>
    </blockquote>
  </Layout>
)
